<template>
  <BaseDetail
    :is-loading="loading"
  >
    <template #header>
      <BaseHeader
        @save="handleSave"
        @close="handleClose"
        @dataBaseDelete="isDataBaseDelete = true"
        :deleteDataBaseBtn="$can('admin', null) && $route.params.id !== 'create'"
        :save-btn="$can('admin') || $can('manager')"
        :delete-btn="false"
        text-delete="Вы действительно хотите удалить пользователя?"
      />
    </template>
    <template #content>
      <DetailView
        v-if="detail"
        v-model="detail"
        ref="content"
      />
      <BaseDeleteConfirm
        v-if="detail"
        :id="Number(detail.id)"
        :isDialog="isDataBaseDelete"
        entity-text="пользователя"
        @closeDialog="isDataBaseDelete = false"
        @delete="handleDelete(1)"
      />
    </template>
  </BaseDetail>
</template>

<script>
import BaseDetail from '@/components/base/BaseDetail'
import DetailView from './detail/DetailView'
import detail from '@/components/mixins/detail'
import BasePreloader from '@/components/base/UI/BasePreloader'
import { email, minLength, required } from 'vuelidate/lib/validators'
import BaseHeader from '@/components/base/BaseDetail/BaseHeader'
import BaseDeleteConfirm from '@/components/base/BaseDeleteConfirm'

export default {
  mixins: [detail],
  name: 'Detail',
  components: { BaseHeader, BaseDetail, DetailView, BasePreloader, BaseDeleteConfirm },
  props: {
    value: Object
  },
  data () {
    return {
      isDataBaseDelete: false
    }
  },
  validations: {
    detail: {
      username: {
        required
      },
      phone: {
        required,
        minLength: minLength(11)
      },
      email: {
        required,
        email,
        isUnique (value) {
          let result = true
          if (this.$v.detail.email.$dirty) {
            result = this.$store.dispatch('auth/isUnique', {
              data: {
                email: value,
                id: this.detail.id
              }
            })
          }
          return result
        }
      },
      organization: {
        id: {
          required
        }
      },
      role: {
        required
      }
    }
  },
  computed: {
    modelIdName () {
      let id = 'id'
      if (this.$route.name === 'organizationUserDetail') {
        id = 'userId'
      }
      return id
    },
    controllerName () {
      return 'user'
    },
    modelData () {
      let data = null
      if (this.$route.name === 'organizationUserDetail' && this.$route.params.userId === 'create') {
        data = {
          organization: {
            address: this.value.address,
            city: this.value.city,
            id: this.value.id,
            name: this.value.name,
            region: this.value.region,
            role: this.value.role
          }
        }
      }
      return data
    }
  }
}
</script>

<style scoped>

</style>
