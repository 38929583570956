<template>
  <BaseDialog
    :value="value"
    :text="$t('message.resetYourPassword') + ': ' + email"
    :label="$t('user.resetPassword')"
    @confirm="handleResetPassword"
    is-confirm-btn
    is-cancel-btn
    @input="$emit('input', $event)"
    :max-width="300"
  >
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/base/BaseDialog'

export default {
  name: 'ResetPassword',
  components: { BaseDialog },
  props: {
    email: String,
    value: Boolean
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    async handleResetPassword () {
      if (!this.isLoading) {
        this.isLoading = true
        if (await this.$store.dispatch('auth/resetPassword', this.email)) {
          this.$emit('input', false)
        }
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
