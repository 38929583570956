<template>
  <v-card
    flat
  >
    <v-card-text>
      <v-row dense>
        <v-col
          cols="5"
          md="6"
        >
          <v-card
            class="mb-2"
            outlined
            tile
          >
            <v-card-text outlined>
              <v-text-field
                v-model="detail.username"
                color="secondary"
                :label="$t('user.username')"
                dense
                outlined
                :error-messages="checkErrors('detail.username')"
              ></v-text-field>
              <BaseMaskedInput
                v-model="detail.phone"
                color="secondary"
                :label="$t('user.phone')"
                dense
                outlined
                :placeholder="'+7 (132) 132 22 44'"
                :error-messages="checkErrors('detail.phone')"
                :input-mask="masks.phoneMask.input"
                :output-mask="masks.phoneMask.output"
              />
              <v-text-field
                v-model="detail.email"
                color="secondary"
                :label="$t('user.email')"
                dense
                outlined
                placeholder="Email@email.domen"
                :error-messages="checkErrors('detail.email')"
              ></v-text-field>
            </v-card-text>
          </v-card>
          <v-card
            class="mt-2"
            outlined
            tile
          >
            <v-card-text>
              <BaseAutocomplete
                v-if="$route.name !== 'organizationUserDetail'"
                v-model="detail.organization"
                item-value="id"
                item-text="name"
                :label="$t('organization.name')"
                url="/organization/autocomplete"
                :chars-to-search="3"
                clearable
                outlined
                dense
                color="secondary"
                hide-details
                :error-messages="checkErrors('detail.organization.id')"
                :prepend-inner-icon="'mdi-magnify'"
              />
              <RoleSelect
                v-if="detail.organization"
                v-model="detail.role"
                is-multiple
                :role-list="detail.organization.role"
                :error-messages="checkErrors('detail.role')"
                :label="$t('organization.role')"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="6"
          md="6"
        >
          <v-card
            flat
          >
            <v-card-text>
              <div>
                <v-checkbox
                  v-model="detail.status"
                  dense
                  class="mr-2 mt-1 mb-2"
                  :label="$t('user.active')"
                ></v-checkbox>
              </div>
              <BaseBtn
                :title="$t('user.resetPassword')"
                @click="isDialogReset = !isDialogReset"
                color="primary"
              />
              <ResetPassword
                class="mt-1"
                v-if="detail.id"
                :email="detail.email"
                :value="isDialogReset"
                @input="isDialogReset = !isDialogReset"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ResetPassword from '@/components/views/account/user/detail/ResetPassword'
import validationErrors from '@/components/mixins/validationErrors'
import BaseAutocomplete from '@/components/base/BaseAutocomplete'
import * as masks from '@/config/masks'
import BaseMaskedInput from '@/components/base/forms/BaseMaskedInput'
import RoleSelect from '@/components/views/account/user/detail/role/RoleSelect'
import BaseBtn from '@/components/base/UI/BaseBtn'
import user from '@/components/mixins/user'


export default {
  mixins: [validationErrors, user],
  name: 'DetailView',
  components: { BaseBtn, ResetPassword, BaseAutocomplete, BaseMaskedInput, RoleSelect },
  props: {
    value: Object
  },
  data () {
    return {
      masks: masks,
      detail: {},
      isDialogReset: false
    }
  },
  inject: ['$v'],
  watch: {
    value: {
      handler () {
        this.detail = this.value
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>

</style>
